import { Typography } from "@mui/material";
import React from "react";

import App from "src/components/App";
import ContentContainer from "src/components/App/components/ContentContainer";
import Loader from "src/components/Loader";
import useUser from "src/hooks/user/useUser";

const Home = () => {
  const loggedInUser = useUser();

  if (!loggedInUser?.user || !loggedInUser?.token) return <Loader />;

  return (
    <App>
      <ContentContainer>
        <Typography variant="h4">Safetycon360</Typography>
      </ContentContainer>
    </App>
  );
};

export default Home;
